<template>
    <div v-if="$route.name!=='login'">
        <div class="bg-brand">
            <div class="container mx-auto flex justify-between text-center px-8">
                <router-link to="/main" class="flex-auto flex justify-start items-center p-4 hover:bg-brand-dark text-white no-underline">БАЗА ВОПРОСОВ</router-link>
                <sub-menu class="flex-1 flex justify-end py-4 text-3xl cursor-pointer onBottomPopper"></sub-menu>
            </div>
        </div>
        <menu-buttons></menu-buttons>
    </div>
</template>

<script>
import MenuButtons from './menu.vue'
import SubMenu from './sub-menu'
export default {
  components: { SubMenu, MenuButtons },
  name: 'AppHeader',
}
</script>
