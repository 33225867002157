import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

Vue.component('font-awesome-icon', FontAwesomeIcon)

library.add(require(`@fortawesome/free-solid-svg-icons/faQuestion`).definition)
library.add(require(`@fortawesome/free-solid-svg-icons/faEye`).definition)
library.add(require(`@fortawesome/free-solid-svg-icons/faFilm`).definition)
library.add(require(`@fortawesome/free-solid-svg-icons/faAngleUp`).definition)
library.add(require(`@fortawesome/free-solid-svg-icons/faAngleDown`).definition)
library.add(require(`@fortawesome/free-solid-svg-icons/faPlus`).definition)
library.add(require(`@fortawesome/free-solid-svg-icons/faMinus`).definition)
library.add(require(`@fortawesome/free-solid-svg-icons/faSearch`).definition)
library.add(require(`@fortawesome/free-solid-svg-icons/faEdit`).definition)
library.add(require(`@fortawesome/free-solid-svg-icons/faTimes`).definition)
library.add(require(`@fortawesome/free-solid-svg-icons/faTimesCircle`).definition)
library.add(require(`@fortawesome/free-solid-svg-icons/faDice`).definition)
library.add(require(`@fortawesome/free-solid-svg-icons/faArrowsAltH`).definition)
library.add(require(`@fortawesome/free-solid-svg-icons/faSpinner`).definition)
library.add(require(`@fortawesome/free-solid-svg-icons/faCheckCircle`).definition)

library.add(require(`@fortawesome/pro-regular-svg-icons/faPlus`).definition)
library.add(require(`@fortawesome/pro-regular-svg-icons/faSpinnerThird`).definition)
library.add(require(`@fortawesome/pro-regular-svg-icons/faTimes`).definition)
library.add(require(`@fortawesome/pro-regular-svg-icons/faCommentAlt`).definition)
library.add(require(`@fortawesome/free-regular-svg-icons/faCheckCircle`).definition)

library.add(require(`@fortawesome/pro-light-svg-icons/faAngleUp`).definition)
library.add(require(`@fortawesome/pro-light-svg-icons/faAngleDown`).definition)

library.add(require(`@fortawesome/free-brands-svg-icons/faTelegramPlane`).definition)

library.add(require(`@fortawesome/pro-regular-svg-icons/faSignOut`).definition)
library.add(require(`@fortawesome/pro-regular-svg-icons/faUserTie`).definition)
library.add(require(`@fortawesome/pro-regular-svg-icons/faTachometerAlt`).definition)
library.add(require(`@fortawesome/pro-regular-svg-icons/faFileAlt`).definition)
library.add(require(`@fortawesome/pro-regular-svg-icons/faTrashAlt`).definition)
library.add(require(`@fortawesome/pro-regular-svg-icons/faReceipt`).definition)
library.add(require(`@fortawesome/pro-regular-svg-icons/faUserCircle`).definition)
library.add(require(`@fortawesome/pro-regular-svg-icons/faMoneyBillWaveAlt`).definition)
library.add(require(`@fortawesome/pro-regular-svg-icons/faEdit`).definition)
