<template>
    <div class="min-h-full h-full font-sans">

        <app-header :key="$route.path"></app-header>
        <div class="flex flex-1 h-full">

            <router-view class="page h-full" :key="$route.path"></router-view>
            <portal-target name="destination">
                <!--
                This component can be located anwhere in your App.
                The slot content of the above portal component will be rendered here.
                -->
            </portal-target>

        </div>

    </div>
</template>

<script>
import axios from 'axios'
import { mapState, mapActions, mapGetters } from 'vuex'
import AppHeader from './components/layout/app-header.vue'
import Auth from './pages/auth.vue'
import ShowReasons from './pages/question/show-reasons'
import vueCookies from 'vue-cookies'
import auth from './store/modules/auth'

export default {

  components: {
    Auth,
    AppHeader,
    ShowReasons,
  },
  created () {
    // // get from cookie mozgoEditorAuth = token store.dispatch.login(token)

    // let b = vueCookies.get('mozgoEditorAuth')
    // console.log(b.access_token)
    // let d = auth.state.token.access_token
    // console.log(d)

    // console.log(auth.state.user)

    // if (b = d) { console.log('bawar') } else { console.log('not equal'); this.loadUserInfo() }

    axios.interceptors.response.use(undefined, (data) => {
      return new Promise((resolve, reject) => {
        if (data.response.status === 401 && data.response.config && !data.response.config.__isRetryRequest) {
          this.$store.dispatch('logout').then(() => this.$router.push('/login'))
        }
        throw data
      })
    })

    if (this.isAuthenticated) {
      axios.defaults.headers.common['Authorization'] = `${this.token.token_type} ${this.token.access_token}`
      this.loadUserInfo()
    }
  },

  methods: {
    ...mapActions(['loadUserInfo', 'initAuthorizationHeader']),
  },

  computed: {
    ...mapGetters(['isAuthenticated', 'user']),
    ...mapState({
      token: state => state.auth.token,

    }),

    noLayout () {
      return !!this.$route.meta.noLayout
    },
  },
}
</script>

<style lang="less">
    @import "styles/app.less";
</style>
<style scoped>

    @tailwind utilities;

    .page {
        @apply w-full;
    }

    .page-enter-active, .page-leave-active, .slide-top-enter-active, .slide-top-leave-active {
        transition: all 0.5s;
    }

    .page-enter {
        margin-left: -100%;
        width: 0px;
        overflow: hidden;
    }

    .page-leave-to {
        margin-left: 100%;
        width: 0px;
        overflow: hidden;
    }

    .slide-top-enter, .slide-top-leave-to {
        margin-top: -100%;
        margin-bottom: 100%;
        height: 0px;
        overflow: hidden;
    }
</style>
