<template>
  <div
    v-if="modal"
    @click.self="isSelfClose ? close : null"
    class="
      animated
      fadeIn
      fixed
      z-50
      pin
      overflow-auto
      bg-smoke-dark
      flex
      h-full
    "
  >
    <div
      class="
        fixed
        max-w-lg
        md:relative
        pin-b pin-x
        align-top
        m-auto
        justify-end
        md:justify-center
        bg-white
        md:rounded
        w-full
        md:h-auto
        md:shadow
        flex-col
        my-6
      "
      :class="{ 'city-max-height': type === 'city-modal' }"
    >
      <h2
        class="text-2xl border-b uppercase px-4 py-2 text-left md:leading-loose"
        v-text="title || '&nbsp;'"
      ></h2>
      <div class="p-4 bg-white">
        <portal-target :name="id"></portal-target>
      </div>
      <span
        @click="close"
        class="absolute pin-t pin-r pt-4 px-4 cursor-pointer"
      >
        <font-awesome-icon
          class="text-grey hover:text-grey-darkest"
          :icon="['fas', 'times']"
        ></font-awesome-icon>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      id: '',
      title: '',
      modal: false,
      type: false,
      isSelfClose: true,
    }
  },

  mounted () {},

  methods: {
    open (name, title = '', type = '', selfClose = true) {
      this.id = name
      this.title = title
      this.type = type
      this.modal = true
      this.isSelfClose = selfClose
      // TODO сделать фокусирувку на первый input
      // this.$nextTick(() => $(this.$el).find('input:visible:eq(0)').trigger('focus'));
    },

    close () {
      this.id = ''
      this.title = ''
      this.type = ''
      this.modal = false
    },
  },
}
</script>
<style scoped>
.city-max-height {
  display: block;
  max-height: 90%;
  overflow-y: scroll;
}
</style>
