import axios from 'axios'
import LocalStorage from '../../LocalStorage'
import vueCookies from 'vue-cookies'

const defaultToken = {
  access_token: null,
  expires_at: null,
  refresh_token: null,
  token_type: null,
}
const state = {
  token: LocalStorage.get('userToken', defaultToken),
  user: {},
}
const getters = {
  isAuthenticated: state => state.token.access_token !== null,
  getAuthorizationHeader: state => `${state.token.token_type} ${state.token.access_token}`,
  user: state => state.user,
}
const actions = {
  login ({ commit, dispatch }, token) {
    return new Promise((resolve) => {
      LocalStorage.set('userToken', token)
      vueCookies.set('mozgoAuth', '')
      vueCookies.set('mozgoAuth', token.access_token)
      vueCookies.set('mozgoEditorAuth', '')
      vueCookies.set('mozgoEditorAuth', token)
      commit('authSuccessful', token)
      dispatch('loadUserInfo')
      resolve(token)
    })
  },
  initAuthorizationHeader ({ commit, dispatch }) {
    commit('setAuthorizationHeader')
  },

  loadUserInfo ({ commit, dispatch }) {
    axios.get('/api/user').then(({ data }) => {
      commit('userInfoLoaded', data)
    })
  },

  logout ({ commit }) {
    return new Promise((resolve) => {
      commit('logout')
      LocalStorage.remove('userToken')
      vueCookies.remove('mozgoAuth')
      vueCookies.remove('mozgoEditorAuth')
      resolve()
    })
  },
}

const mutations = {
  logout (state) {
    state.token = defaultToken
  },

  authSuccessful (state, token) {
    state.token = token
    axios.defaults.headers.common['Authorization'] = `${token.token_type} ${token.access_token}`
  },

  userInfoLoaded (state, user) {
    state.user = user
  },
}

export default {
  getters,
  mutations,
  actions,
  state,
}
