<template xmlns="http://www.w3.org/1999/html">
    <form @submit.prevent="auth"
          class="bg-brand flex h-full w-full justify-center items-center text-center container-md flex ">
        <div class="bg-white mx-auto my-auto rounded">
            <div class="border-b border-brand">
                <p class="text-2xl px-8 py-4 ">ВХОД</p>
            </div>
            <div class="py-4 flex px-8">
                <div class="flex-col">
                    <div class="pt-1 pb-3 flex-1">
                        <input v-model="form.email" class="border-2 flex w-full p-2 leading-tight" placeholder="Логин"
                               type="text"/>
                    </div>
                    <div class="pt-1 pb-3 flex-1">
                        <input v-model="form.password" class="border-2 flex w-full p-2 leading-tight"
                               placeholder="Пароль" type="password"/>
                    </div>
                    <div class="flex flex-1 mr-8 pr-1 justify-start">
                        <label class="">
                            <input class="checkbox" type="checkbox" name="checkbox-test">
                            <span class="checkbox-custom"></span>
                        </label>
                        <p class="p-1">Запомнить компьютер</p>

                    </div>

                </div>

            </div>
            <div class="pb-8 pt-4 flex px-8">
                <button type="submit" class="bg-brand px-8 py-2 no-underline text-white rounded w-full">Войти
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import Tooltip from '../components/tooltip'
import Form from 'form-backend-validation'
import axios from 'axios'

export default {
  name: 'Auth',
  components: { Tooltip },
  data () {
    return {
      form: new Form(['email', 'password'], { http: axios }),
    }
  },

  methods: {
    auth () {
      this.form.post('/login').then((token) => this.$store.dispatch('login', token)).then(() => {
        this.$router.push('/')
      })
    },
  },
}
</script>

<style scoped>

    .checkbox {
        display: none;
    }

    .checkbox-custom {
        position: relative;
        width: 19px;
        height: 19px;
        border: 2px solid #ccc;
        border-radius: 3px;
    }

    .checkbox-custom,
    .label {
        display: inline-block;
        vertical-align: middle;
    }

    .checkbox:checked + .checkbox-custom::before {
        content: "";
        display: block;
        position: absolute;
        top: 2px;
        right: 2px;
        bottom: 2px;
        left: 2px;
        background: #544e68;
        border-radius: 2px;
    }

</style>
