<template>

    <transition name="modal">
        <portal to="destination">
            <div class="modal-backdrop" v-show="show" @click.self="$emit('close')">
                <div class="modal">
                    <font-awesome-icon class="text-brand-red cursor-pointer text-2xl justify-end"
                                       @click="$emit('close')"
                                       :icon="['fas', 'times-circle']">
                    </font-awesome-icon>
                    <div class="justify-center items-center flex pb-4">
                        <p class="text-2xl text-black font-medium">История комментариев</p>
                    </div>
                    <div v-for="revision in question_revision" class="flex -mx-2 pb-6 border-b">
                        <div class="mx-2 w-1/4">
                            <label class="align-center justify-center font-semibold block my-4">Вопрос</label>
                            <textarea v-model="revision.question" class="p-2 border-2 resize-none w-full inputer"
                                      rows="7" disabled></textarea>
                        </div>

                        <div class="mx-2 w-1/4">
                            <label class="align-center justify-center font-semibold block my-4">Подвязка</label>
                            <textarea v-model="revision.supply" class="p-2 border-2 resize-none w-full inputer" rows="7"
                                      disabled></textarea>
                        </div>
                        <div class="mx-2 w-1/4">
                            <div class="">
                                <label class="align-center justify-center font-semibold block my-4">Ответ</label>
                                <textarea v-model="revision.answer" class="p-2 border-2 resize-none w-full inputer"
                                          rows="4" disabled></textarea>
                            </div>
                        </div>
                        <div class="mx-2 w-1/4">
                            <div class="">
                                <label class="align-center justify-center font-semibold block my-4">Причина</label>
                                <textarea v-model="revision.reason" class="p-2 border-2 resize-none w-full inputer"
                                          rows="4" disabled></textarea>
                            </div>
                            <div class="flex">
                                <p class="mr-2">Отправлено</p>
                                <p v-text="created_time(revision)"></p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </portal>
    </transition>

</template>

<script>

import moment from 'moment'

export default {
  name: 'ShowReasons',
  props: ['question_revision'],
  data () {
    return {
      reason: '',
      ext: '',
      show: true,
    }
  },

  methods: {
    created_time (revision) {
      return moment(revision.created_at).format('DD.MM.YYYY, hh:mm')
    },
    dismiss () {
      document.body.style.overflow = 'auto'
      this.$emit('close')
      return this.show === false
    },
  },

  created () {
    document.body.style.overflow = 'hidden'
    const escapeHandler = e => {
      if (e.key === 'Escape' && this.show) {
        this.dismiss()
      }
    }
    document.addEventListener('keydown', escapeHandler)
    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', escapeHandler)
      this.dismiss()
    })
  },

}

</script>

<style scoped>
    .modal-backdrop {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        padding: 2rem;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .modal {
        margin-left: auto;
        margin-right: auto;
        max-width: 1200px;
        margin-top: 2rem;
    }

    .modal {
        background-color: #fff;
        border-radius: 0.25rem;
        padding: 2rem;
        -webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
        0 5px 15px 0 rgba(0, 0, 0, 0.08);
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
        0 5px 15px 0 rgba(0, 0, 0, 0.08);
    }

</style>
