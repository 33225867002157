import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import axios from 'axios'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      name: 'login',
      path: '/login',
      component: () => import(/* webpackChunkName: "auth" */ '@/pages/auth'),
      meta: { auth: false, role: ['author', 'corrector', 'editor', 'freelancer'] },
    },
    {
      path: '/',
      redirect: '/main',
      meta: { auth: true, role: ['author', 'corrector', 'editor', 'freelancer'] },
    },
    {
      name: 'main',
      path: '/main',
      component: () => import(/* webpackChunkName: "main" */ '@/pages/main/index'),
      children: [],
      meta: { auth: true, role: ['author', 'corrector', 'editor', 'freelancer'] },
    },
    {
      name: 'question',
      path: '/question/:questionId',
      component: () => import(/* webpackChunkName: "question" */ '@/pages/question/index'),
      meta: { auth: true, role: ['author', 'corrector', 'editor', 'freelancer'] },
    },
    {
      // ?
      name: 'eventOrder',
      path: '/eventOrders',
      component: () => import ('@/pages/eventOrders/index'),
      meta: { auth: true, role: ['editor'] },
    },
    {
      name: 'city',
      path: '/city',
      component: () => import(/* webpackChunkName: "city" */ '@/pages/city/index'),
      meta: { auth: true, role: ['corrector', 'editor'] },
    },
    {
      name: 'partners',
      path: '/partners',
      component: () => import(/* webpackChunkName: "city" */ '@/pages/partners/index'),
      meta: { auth: true, role: ['corrector', 'editor'] },
    },
    {
      name: 'templates',
      path: '/templates',
      component: () => import(/* webpackChunkName: "game-templates" */ '@/pages/game_templates/index'),
      meta: { auth: true, role: ['corrector', 'editor'] },
    },
    {
      name: 'template',
      path: '/templates/:id',
      component: () => import(/* webpackChunkName: "question" */ '@/pages/game_templates/form'),
      children: [
        {
          name: 'template_questions',
          path: 'tour/:tour',
          component: () => import(/* webpackChunkName: "question" */ '@/pages/game_templates/questions'),
          meta: { auth: true, role: ['corrector', 'editor'] },
        },
      ],
      meta: {
        auth: true,
        role: ['corrector', 'editor'],
      },
    },
    {
      name: 'statistics',
      path: '/statistics',
      redirect: '/statistics/authors',
      component: () => import('@/pages/statistics/index'),
      children: [
        {
          name: 'statistics_form',
          path: '/statistics/:type',
          component: () => import('@/pages/statistics/form'),
          meta: {
            auth: true,
            role: ['corrector', 'editor'],
          },
        },
      ],
      meta: {
        auth: true, role: ['corrector', 'editor'],
      },
    },
    {
      name: 'games',
      path: '/games',
      component: () => import(/* webpackChunkName: "game-templates" */ '@/pages/games/index'),
      meta: {
        auth: true,
        role: ['corrector', 'editor'],
      },
    },
    {
      name: 'game',
      path: '/games/:id',
      component: () => import(/* webpackChunkName: "question" */ '@/pages/games/game/index'),
      children: [
        {
          name: 'game_questions',
          path: 'tour/:tour/serial_number/:serial_number',
          component: () => import(/* webpackChunkName: "question" */ '@/pages/games/game/questions'),
          meta: {
            auth: true,
            role: ['corrector', 'editor'],
          },
        },
      ],
      meta: {
        auth: true,
        role: ['corrector', 'editor'],
      },
    },
    {
      name: 'promocodes',
      path: '/promocodes',
      component: () => import('@/pages/promocodes/form'),
      meta: {
        auth: true,
        role: ['editor'] },
    },
  ],
})
let loadUserInfo = (token) => {
  return axios.get('/api/user', {
    headers: {
      'Authorization': `${token}`,
    },
  }
  ).then((response) => {
    return response.data
  })
}

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.auth === undefined || record.meta.auth === null)) {
    next()
    return
  }

  if (to.matched.some(record => record.meta.auth) && !store.getters.isAuthenticated) {
    next('/login')
    return
  }

  if (to.matched.some(record => !record.meta.auth) && store.getters.isAuthenticated) {
    next('/')
    return
  }
  if (to.meta.role) {
    if (store.getters.isAuthenticated && store.getters.getAuthorizationHeader) {
      let user = await loadUserInfo(store.getters.getAuthorizationHeader)
      if (to.meta.role.indexOf(user.role) >= 0) {
        next()
        return
      } else {
        next(false)
        store.dispatch('logout')
        router.push('/login')
        return
      }
    }
  }

  next()
})

export default router
