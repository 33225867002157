import Vue from 'vue'
import moment from 'moment'

let formatCurrency = (value, currencySign = '', infinite = false) => {
  value = parseFloat(value)

  if (!isFinite(value) && infinite) {
    return '∞'
  }

  if (!isFinite(value) || (!value && value !== 0)) {
    return ''
  }

  let stringified = Math.abs(value).toFixed(2)

  let _int = stringified.slice(0, -1 - 2)

  let i = _int.length % 3

  let head = i > 0
    ? (_int.slice(0, i) + (_int.length > 3 ? ' ' : ''))
    : ''

  let _float = stringified.slice(-1 - 2)

  let sign = value < 0 ? '-' : ''

  return sign + head +
        _int.slice(i).replace(/(\d{3})(?=\d)/g, '$1 ') +
        _float + currencySign
}

Vue.filter('currency', value => {
  return formatCurrency(value, ' ₽')
})

Vue.filter('format_number', value => {
  return formatCurrency(value)
})

Vue.filter('infinite_format_number', value => {
  return formatCurrency(value, '', true)
})

/**
 * Format the given date.
 */
Vue.filter('date', value => {
  if (value === null) {
    return 'Н/О'
  }
  return moment(value).format('DD.MM.YYYY')
})

/**
 * Format the given date as a timestamp.
 */
Vue.filter('datetime', value => {
  if (value === null) {
    return 'Н/О'
  }
  return moment(value).format('DD.MM.YYYY HH:mm')
})

/**
 * Format the given date as a timestamp.
 */
Vue.filter('duration', value => {
  if (value === null) {
    return ''
  }

  let minutes = Math.floor(value / 60)
  let seconds = value % 60

  if (value >= 60) {
    return `${minutes} мин. ${seconds} сек.`
  }

  return `${seconds} сек.`
})
