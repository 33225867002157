import Vue from 'vue'
import App from './App.vue'
import VuePortal from 'portal-vue'

import './registerServiceWorker'

import './font-awesome'
import './vue-filters'
import Modal from './components/Modal'

import router from './router/index'
import store from './store/index'
import { sync } from 'vuex-router-sync'

import VCalendar from 'v-calendar'
import 'v-calendar/lib/v-calendar.min.css'
sync(store, router)

Vue.config.productionTip = false

Vue.use(VuePortal)
// Use v-calendar, v-date-picker & v-popover components
Vue.use(VCalendar, {
  datePickerTintColor: '#6a6384',
  datePickerShowDayPopover: false,
})

Vue.mixin({
  computed: {
    modalHolder () {
      return this.$root.$refs['modal']
    },
    isAuthor () {
      return this.$store.state.auth.user.role === 'author'
    },
    isEditor () {
      return this.$store.state.auth.user.role === 'editor'
    },
    isFreelancer () {
      return this.$store.state.auth.user.role === 'freelancer'
    },
    isCorrector () {
      return this.$store.state.auth.user.role === 'corrector'
    },
  },
})

new Vue({
  router,
  store,
  render: h => h('div', { 'class': 'h-full' }, [
    h(App),
    h(Modal, {
      ref: 'modal',
    }),
  ]),
}).$mount('#app')
