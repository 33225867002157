<template>
    <div class="container mx-auto flex justify-between text-center px-8 py-8 h-24">
        <div class="h-full flex justify-start ml-4">
            <portal-target name="filter-panel" slim></portal-target>
            <router-link to="/main" title="Список вопросов"
                         class="flex text-grey-darkest no-underline p-1 mx-1 bg-grey-light rounded hover:bg-grey whitespace-no-wrap h-full">
                <font-awesome-icon class="w-6 my-auto" :icon="['fas', 'question']"></font-awesome-icon>
            </router-link>
            <router-link to="/city" title="Добавление города" v-if="!isAuthor && !isFreelancer &&(isEditor || isCorrector)"
                         class="flex text-grey-darkest no-underline p-1 mx-1 bg-grey-light rounded hover:bg-grey whitespace-no-wrap h-full">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 p-1 my-auto" viewBox="0 0 17.36 18.02">
                    <g data-name="Слой 2">
                        <path fill="currentColor" d="M7 18v-7.35H0V7.37h7V0h3.4v7.37h7v3.28h-7V18z" data-name="Слой 1"/>
                    </g>
                </svg>
            </router-link>
            <router-link to="/statistics" title="Статистика" v-if="!isAuthor && !isFreelancer &&(isEditor || isCorrector)"
                         class="flex text-grey-darkest no-underline p-1 mx-1 bg-grey-light rounded hover:bg-grey whitespace-no-wrap h-full">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 p-1 my-auto" viewBox="0 0 27 24.5">
                    <defs/>
                    <g id="Слой_2" data-name="Слой 2">
                        <g id="Слой_1-2" data-name="Слой 1">
                            <rect class="cls-1" x="19" width="8" height="24.5" rx="1.49" ry="1.49"/>
                            <path class="cls-1" fill="currentColor"
                                  d="M15.07 24.5h-3.14a2.43 2.43 0 0 1-2.43-2.43V10.64a2.43 2.43 0 0 1 2.43-2.43h3.14a2.43 2.43 0 0 1 2.43 2.43v11.43a2.43 2.43 0 0 1-2.43 2.43zm-3.14-14.29a.44.44 0 0 0-.43.43v11.43a.44.44 0 0 0 .43.43h3.14a.44.44 0 0 0 .43-.43V10.64a.44.44 0 0 0-.43-.43zM5.34 24.5H2.66A2.66 2.66 0 0 1 0 21.84v-4.3a2.66 2.66 0 0 1 2.66-2.66h2.68A2.66 2.66 0 0 1 8 17.54v4.3a2.66 2.66 0 0 1-2.66 2.66zm-2.68-7.62a.67.67 0 0 0-.66.66v4.3a.67.67 0 0 0 .66.66h2.68a.67.67 0 0 0 .66-.66v-4.3a.67.67 0 0 0-.66-.66z"/>
                        </g>
                    </g>
                </svg>
            </router-link>
            <router-link to="/templates" title="Шаблоны игр" v-if="!isAuthor && !isFreelancer &&(isEditor || isCorrector)"
                         class="flex text-grey-darkest no-underline p-1 mx-1 bg-grey-light rounded hover:bg-grey whitespace-no-wrap h-full">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 p-1 my-auto" viewBox="0 0 30 21.52">
                    <defs/>
                    <g id="Слой_2" data-name="Слой 2">
                        <g id="Слой_1-2" data-name="Слой 1">
                            <path class="cls-1" fill="currentColor"
                                  d="M20.37 17.16H3.93A3.93 3.93 0 0 1 0 13.23v-9.6A3.64 3.64 0 0 1 3.63 0h16.74a3.93 3.93 0 0 1 3.93 3.93v9.3a3.93 3.93 0 0 1-3.93 3.93zM3.63 2A1.63 1.63 0 0 0 2 3.63v9.6a1.93 1.93 0 0 0 1.93 1.93h16.44a1.93 1.93 0 0 0 1.93-1.93v-9.3A1.94 1.94 0 0 0 20.37 2z"/>
                            <rect class="cls-1" x="6.35" y="5.35" width="23.65" height="16.16" rx="2.93" ry="2.93"/>
                        </g>
                    </g>
                </svg>
            </router-link>
            <router-link to="/games" title="Игры" v-if="!isAuthor && !isFreelancer &&(isEditor || isCorrector)"
                         class="flex text-grey-darkest no-underline p-1 mx-1 bg-grey-light rounded hover:bg-grey whitespace-no-wrap h-full">
                <font-awesome-icon class="w-6 my-auto text-black text-base" :icon="['fas', 'dice']"></font-awesome-icon>
            </router-link>
            <router-link to="/main?trash=1" title="Корзина вопросов" v-if="!isAuthor && !isFreelancer &&(isEditor || isCorrector)"
                         class="flex text-grey-darkest no-underline p-1 mx-1 bg-grey-light rounded hover:bg-grey whitespace-no-wrap h-full">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 my-auto" viewBox="0 0 34 34">
                    <defs>
                        <clipPath id="clip-path-5">
                            <path class="cls-2" fill="currentColor"
                                  d="M18 16a1 1 0 0 1 2 0v6a1 1 0 0 1-2 0zm-4 0a1 1 0 0 1 2 0v6a1 1 0 0 1-2 0zm9-4H11v13a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1zm-3-2V9a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v1zm2 0h4a1 1 0 0 1 0 2h-1v13a3 3 0 0 1-3 3H12a3 3 0 0 1-3-3V12H8a1 1 0 0 1 0-2h4V9a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3z"/>
                        </clipPath>
                    </defs>
                    <g id="Слой_2" data-name="Слой 2">
                        <g clip-path="url(#clip-path-5)">
                            <path fill="currentColor" class="cls-2" d="M5 5h24v24H5z"/>
                        </g>
                    </g>
                </svg>
            </router-link>
            <router-link to="/eventOrders" title="Ожидают оплаты" v-if="!isAuthor && !isFreelancer &&(isEditor || isCorrector)"
                         class="flex align-center text-grey-darkest no-underline p-2 mx-1 bg-grey-light rounded hover:bg-grey whitespace-no-wrap h-full">

                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="money-check-alt" class="svg-inline--fa fa-money-check-alt fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M608 32H32C14.33 32 0 46.33 0 64v384c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zM176 327.88V344c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-16.29c-11.29-.58-22.27-4.52-31.37-11.35-3.9-2.93-4.1-8.77-.57-12.14l11.75-11.21c2.77-2.64 6.89-2.76 10.13-.73 3.87 2.42 8.26 3.72 12.82 3.72h28.11c6.5 0 11.8-5.92 11.8-13.19 0-5.95-3.61-11.19-8.77-12.73l-45-13.5c-18.59-5.58-31.58-23.42-31.58-43.39 0-24.52 19.05-44.44 42.67-45.07V152c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8v16.29c11.29.58 22.27 4.51 31.37 11.35 3.9 2.93 4.1 8.77.57 12.14l-11.75 11.21c-2.77 2.64-6.89 2.76-10.13.73-3.87-2.43-8.26-3.72-12.82-3.72h-28.11c-6.5 0-11.8 5.92-11.8 13.19 0 5.95 3.61 11.19 8.77 12.73l45 13.5c18.59 5.58 31.58 23.42 31.58 43.39 0 24.53-19.05 44.44-42.67 45.07zM416 312c0 4.42-3.58 8-8 8H296c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h112c4.42 0 8 3.58 8 8v16zm160 0c0 4.42-3.58 8-8 8h-80c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h80c4.42 0 8 3.58 8 8v16zm0-96c0 4.42-3.58 8-8 8H296c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h272c4.42 0 8 3.58 8 8v16z"></path></svg>
            </router-link>

            <router-link to="/partners" title="Партнеры" v-if="!isAuthor && !isFreelancer &&(isEditor || isCorrector)"
                         class="flex align-center text-grey-light no-underline p-2 mx-1 bg-grey-light rounded hover:bg-grey whitespace-no-wrap h-full">

                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M20.822 18.096c-3.439-.794-6.641-1.49-5.09-4.418 4.719-8.912 1.251-13.678-3.732-13.678-5.081 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-2.979.688-3.178 2.143-3.178 4.663l.005 1.241h10.483l.704-3h1.615l.704 3h10.483l.005-1.241c.001-2.52-.198-3.975-3.177-4.663zm-8.231 1.904h-1.164l-.91-2h2.994l-.92 2z"/></svg>
            </router-link>

            <portal-target name="restore-btn" slim></portal-target>
            <a href="/promocodes" title="Генерация промокодов"  v-if="!isAuthor && !isFreelancer &&(isEditor || isCorrector)" :class="{'bg-brand-light text-white' : $route.path.includes('promocodes')}"
                         class="flex text-grey-darkest no-underline p-1 mx-1 bg-grey-light rounded hover:bg-grey whitespace-no-wrap h-full">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 my-auto" fill="none" viewBox="0 0 24 24">
                    <path fill="#333"
                          d="M23.456 13.325l-.364-.496a1.566 1.566 0 01-.015-1.825l.354-.502a1.565 1.565 0 00-.54-2.281l-.542-.29a1.559 1.559 0 01-.801-1.638l.103-.605a1.562 1.562 0 00-1.475-1.82l-.615-.024a1.567 1.567 0 01-1.436-1.126l-.172-.59a1.559 1.559 0 00-2.12-.999l-.565.241a1.558 1.558 0 01-1.78-.393l-.409-.458a1.559 1.559 0 00-2.34.02l-.404.462a1.564 1.564 0 01-1.775.418l-.57-.236a1.566 1.566 0 00-2.105 1.033l-.163.595a1.553 1.553 0 01-1.416 1.146l-.615.034c-.939.054-1.618.92-1.445 1.845l.113.604a1.559 1.559 0 01-.777 1.648l-.536.3a1.56 1.56 0 00-.502 2.287l.364.496c.393.541.403 1.274.015 1.825l-.354.501a1.565 1.565 0 00.54 2.282l.542.29c.59.315.914.974.801 1.638l-.103.605a1.562 1.562 0 001.475 1.82l.615.024c.669.03 1.249.482 1.436 1.126l.172.59a1.559 1.559 0 002.12.999l.565-.241a1.558 1.558 0 011.78.393l.409.457c.629.699 1.726.694 2.34-.02l.404-.462a1.564 1.564 0 011.775-.418l.57.237a1.566 1.566 0 002.105-1.033l.163-.595a1.553 1.553 0 011.416-1.146l.614-.034a1.561 1.561 0 001.446-1.845l-.113-.605a1.559 1.559 0 01.777-1.647l.536-.3a1.56 1.56 0 00.502-2.287zM6.386 9.962a2.418 2.418 0 012.42-2.42 2.418 2.418 0 012.42 2.42 2.418 2.418 0 01-2.42 2.42 2.42 2.42 0 01-2.42-2.42zm2.887 6.24a.592.592 0 01-.831.079.592.592 0 01-.079-.831l6.35-7.647a.592.592 0 01.83-.079c.251.207.286.58.079.831l-6.349 7.647zm5.911.251a2.418 2.418 0 01-2.42-2.42 2.418 2.418 0 012.42-2.42 2.418 2.418 0 012.42 2.42 2.418 2.418 0 01-2.42 2.42z"/>
                </svg>
            </a>
        </div>
        <div v-if="$route.path == '/main'"
             class="flex justify-end h-full bg-grey-light rounded text-grey-darkest group hover:bg-grey mx-4 my-auto">
            <font-awesome-icon @click="$refs.search.focus()" class="w-6 my-auto mx-2"
                               :icon="['fas', 'search']"></font-awesome-icon>
            <input ref="search" type="text" v-model="like" style="outline:none"
                   class="h-full px-4 py-2 bg-grey-light group-hover:bg-grey whitespace-no-wrap"/>

        </div>

    </div>
</template>

<script>
import clone from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'

export default {
  data () {
    return {
      like: this.$route.query['like'] || '',
    }
  },
  watch: {
    like: function (val) {
      this.newSearch(val)
      let query = this.$route.query
      query['page'] = 1
    },
  },
  methods: {
    newSearch: debounce(function (like) {
      let query = clone(this.$route.query)
      query['like'] = like
      let searchQuery = {}
      for (let key in query) {
        if (query[key] === '') {
          continue
        }
        searchQuery[key] = query[key]
      }
      this.$router.push({ query: searchQuery })
    }, 500),
  },
}
</script>

<style scoped>
    @tailwind utilities;

    .router-link-active {
        @apply .bg-brand-light .text-white
    }
</style>
