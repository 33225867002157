<template>
    <on-click-outside :do="close">
        <div class="relative" :class="{ 'is-active': isOpen }">
            <button ref="button" @click.prevent="switchPopper" type="button" class="text-white search-select-input">=</button>
            <div ref="panel" v-show="isOpen" class="w-48 mt-6 ml-4 absolute">
                <div class="modal-container w-full flex flex-col mx-auto justify-center items-center text-center">
                    <div @click.prevent="goToEditor" class="p-1 text-base text-brand w-full hover:bg-grey-light no-underline">
                        Перейти в редактор
                    </div>
                    <div @click.prevent.stop="goToSalary" class="p-1 text-base text-brand w-full hover:bg-grey-light no-underline">
                        Моя зарплата
                    </div>
                    <button @click.prevent="logout" class="p-1 text-base text-brand w-full hover:bg-grey-light">
                        Выйти
                    </button>
                </div>
            </div>
        </div>
    </on-click-outside>
</template>

<script>
import Popper from 'popper.js'
import OnClickOutside from '../OnClickOutside.vue'

export default {
  name: 'SubMenu',
  components: {
    OnClickOutside,
  },
  data () {
    return {
      ext: '',
      isOpen: false,
    }
  },
  beforeDestroy () {
    if (this.popper !== undefined) {
      this.popper.destroy()
    }
  },
  methods: {
    switchPopper () {
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        this.$nextTick(() => {
          this.setupPopper()
        })
      }
    },
    close () {
      if (!this.isOpen) {
        return
      }
      this.isOpen = false
    },
    setupPopper () {
      if (this.popper === undefined) {
        this.popper = new Popper(this.$refs.button, this.$refs.panel, {
          placement: 'bottom',
          positionFixed: true,
        })
      } else {
        this.popper.scheduleUpdate()
      }
    },
    goToEditor () {
      window.open('https://editor.mozgo.com', '_blank')
      this.close()
    },
    goToSalary () {
      window.open('https://editor.mozgo.com/salary', '_blank')
      this.close()
    },
    logout () {
      this.$store.dispatch('logout')
      this.$router.push({ name: 'login' })
    },
  },
}
</script>

<style scoped>

    .modal-container {
        height: 74px;
        background-color: #fff;
        border-radius: 10px;
        -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
        transition: all .3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }

</style>
